<template>
    <div>
        <block v-if="loading"></block>
               
        <div class="background rounded-lg">
            <!-- <date-filter 
                v-if="!loading" 
                class="mt-3" 
                nameOfDate="History" 
                :dataToFilter="batchs" 
                :search="{ typeData: this.filter.typeData, showFNS: this.filter.showFNS }" 
                :filterEndpoint="$API.invoices.getInvoicesFilteringByDates" 
                :isFilteredByOutsource="isFiltered"
                @filtered-data="dateFilterHandler" 
            /> -->
            <!-- <div 
                style="padding: 20px;"
            >
                <v-btn 
                    @click.prevent="showModal = !showModal"
                    class="btn-add"
                >
                    Ver modal (esto es de prueba!)
                </v-btn>
            </div> -->
            <GeneralFilter 
                class="mt-9" 
                :breakline="true" 
                :isAutoScrolled="true"
                :search="{ ...this.filter }" 
                :filterEndpoint="$API.accountingperiodbatch.getAccountingPeriodBatch" 
                @emptyFilter="clearFilter" 
                @filtered="filterHandler" 
            >
                <DxDataGrid 
                    key-expr="id_accounting_period_batch" 
                    :data-source="batchs" 
                    :scrolling="{ useNative: true }"    
                    :column-auto-width="true" 
                    @exporting="onExporting"
                >
                    <DxExport :enabled="true"/>
                    <DxPager :showPageSizeSelector="true" />
                    <DxPaging :pageSize="5" />
                    <DxColumn :min-width="80" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn :min-width="80" cell-template="disable-template" caption=""></DxColumn>
                    <DxColumn :min-width="200" data-field="id_accounting_period_batch" caption="ID"></DxColumn>
                    <DxColumn :min-width="200" data-field="BatchDate" caption="Fecha del lote"></DxColumn>
                    <DxColumn :min-width="200" data-field="Period_FromFormatDate" caption="Fecha Inicial"></DxColumn>
                    <DxColumn :min-width="200" data-field="Period_ToFormatDate" caption="Fecha Final"></DxColumn>
                    <DxColumn :min-width="200" data-field="specification" caption="Especificación"></DxColumn>
                    <DxColumn :min-width="80" data-field="" caption=""></DxColumn>

                    <template #show-template="{data}">
                        <div>
                            <a href="#" class="mx-1" @click.prevent.stop="showBatchDetail(data)">
                                <v-icon>mdi-eye</v-icon>
                            </a>
                        </div>
                    </template>
                    <template #disable-template="{data}">
                        <a href="#" class="mx-1" @click.prevent.stop="disableBatch(data.data.id_accounting_period_batch)">
                            <v-icon>mdi-delete</v-icon>
                        </a>
                    </template>
                </DxDataGrid>
            </GeneralFilter>
        </div>

        <!-- Show Batch Modal -->
        <v-dialog 
            v-model="showModal" 
            scrollable
            width="80%"
        >
            <v-card
                style="background-color: white !important; min-height: 300px !important;"
            >
                <v-card-title>Detalles del Lote...</v-card-title>
                <DxDataGrid v-if="batchDetails?.AccountingEntries"
                    key-expr="id_accounting_period_batch" 
                    :data-source="batchDetails?.AccountingEntries" 
                    :scrolling="{ useNative: true }"    
                    :column-auto-width="true" 
                    @exporting="onExporting"
                >
                    <DxExport :enabled="true"/>
                    <DxPager :showPageSizeSelector="true" />
                    <DxPaging :pageSize="5" />
                    <DxColumn :min-width="80" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn :min-width="200" data-field="ID_Accounting_item" caption="Correlativo"></DxColumn>
                    <DxColumn dataField="EntryFormatDate" caption="Fecha"  ></DxColumn>
                    <DxColumn dataField="accounting_entry_comment" caption="Concepto" ></DxColumn>
                    <DxColumn dataField="accounting_entry_value_toShow" caption="Valor" ></DxColumn>
                    <DxColumn dataField="accountingEntryTypeName" caption="Tipo" ></DxColumn>
                    <template #show-template="{data}">
                        <a href="#" class="mx-1" @click.prevent.stop="showAccountingEntryDetail(data.data)">
                            <v-icon>mdi-eye</v-icon>
                        </a>
                    </template>
                </DxDataGrid>
            </v-card>
        </v-dialog>

        <alerts style="z-index: 20000 !important;"
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        >
        </alerts>
    </div>
</template>

<script>
    import { DxDataGrid, DxColumn, DxPaging, DxExport, DxPager} from 'devextreme-vue/data-grid';
    import { Workbook } from 'exceljs';
    import { saveAs } from 'file-saver-es';
    import { exportDataGrid } from 'devextreme/excel_exporter';
    //import InvoiceDetails from "@/views/invoices/InvoiceDetails"; 
    import Block from "@/components/Block";
    import Alerts from "@/components/Alerts";
    // import DateFilter from '@/components/DateFilter.vue';
    import GeneralFilter from '@/components/GeneralFilter.vue';
    import { numberWithCommas } from '@/helpers/money';

    export default {
        name: "History",
        components: {
            DxDataGrid,
            DxColumn,
            DxPaging,
            DxPager,
            Block,
            Alerts,
            DxExport,
            // DateFilter,
            GeneralFilter
        },
        data() {
            return {
                loading: false,
                form: {},
                showFilter: false,
                showModal: false,
                batchs: [],
                batchDetails: {},
                filter: {
                    codPointSale: localStorage.getItem("codPointSale"),
                    ID_Branch: JSON.parse(localStorage.getItem("user")).branch || "",
                    ID_Business: JSON.parse(localStorage.getItem("user")).businessid || "" ,
                    user: JSON.parse(localStorage.getItem("user")).id || "",
                    status: 1,
                    istemplate: 0,
                    typeData: '01',
                    showFNS: this.$store.getters['rolesUser/getCustomRole'](247) ? true : false
                },
                filterType: [
                    {
                        name: 'CRÉDITO FISCAL', key: 'CCF',
                    }, {
                        name: 'CONSUMIDOR FINAL', key: 'FAF'
                    }, {
                        name: 'NOTA DE CREDITO', key: 'NC'
                    }, {
                        name: 'NOTA DE DÉBITO', key: 'ND'
                    }
                ],
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                isFiltered: false
            }
        },
        watch: {
          'showModal'() {
              if (!this.showModal) this.batchDetails = {}
          },
          /* 'filter.typeData'()  {
                this.getAllInvoices()
          } */
        },
        computed: {
            
        },
        async mounted() {
            this.filter.ID_Branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.filter.ID_Business = JSON.parse(localStorage.getItem("user")).businessid || "" ;
            await this.getBatches()
        },
        methods: {
            formatDateToHour(data) {
                const completeDate = new Date(data);
                const hour = completeDate.getHours();
                const minutes = completeDate.getMinutes();
                const seconds = completeDate.getSeconds();

                return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
            },
            filterHandler(response) {
                this.isFiltered = true;
                this.batchs = [...response];
                this.formatInvoiceFields();
            },
            dateFilterHandler(filteredData) {
                this.batchs = filteredData;
                this.formatInvoiceFields();
            },
            formatInvoiceFields() {
                const dictionary = {
                    '00': "Prueba",
                    '01': "Producción"
                };
                const { Currency } = JSON.parse(localStorage.getItem('branch'));
                for(const index in this.batchs) {
                    const { IdentificacionHorEmi, InvoiceType, SystemDateInsert, IdentificacionTipoDteDesc } = this.batchs[ index ];

                    this.batchs[index].AmbienteToShow = dictionary[this.batchs[index].IdentificacionAmbiente];
                    this.batchs[index].InvoiceTotalPagarToShow = `${Currency}${numberWithCommas(this.batchs[index].InvoiceTotalPagar.toFixed(2))}`;

                    if( InvoiceType === 'TCKS' ) {
                        this.batchs[ index ].HorEmi = this.formatDateToHour(SystemDateInsert);
                        this.batchs[ index ].TipoDocumento = "TICKET";
                    } else {
                        if( IdentificacionTipoDteDesc != null ) {
                            this.batchs[ index ].TipoDocumento = IdentificacionTipoDteDesc;
                        } else {
                            this.batchs[ index ].TipoDocumento = "";
                        }
                        if( IdentificacionHorEmi != null ) {
                            this.batchs[ index ].HorEmi = IdentificacionHorEmi;
                        } else { 
                            this.batchs[ index ].HorEmi = "";
                        }
                    }
                    // {{ data.data.IdentificacionHorEmi != null ? data.data.IdentificacionHorEmi : "" }}
                    //     {{ data.data.InvoiceType === 'TCKS' ? formatDateToHour(data.data.SystemDateInsert) : "" }}
                }
            },
            clearFilter(){
                this.isFiltered = false;
                this.getBatches();
            },
            onExporting(e) {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('HistorialDeFacturas');

                exportDataGrid({
                    component: e.component,
                    worksheet,
                    topLeftCell: { row: 4, column: 1 },
                }).then((cellRange) => {
                    // header
                    const headerRow = worksheet.getRow(2);
                    headerRow.height = 30;
                    worksheet.mergeCells(2, 1, 2, 8);

                    headerRow.getCell(1).value = 'Historial de Facturas Procesadas';
                    headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 };
                    headerRow.getCell(1).alignment = { horizontal: 'center' };

                    // footer
                    const footerRowIndex = cellRange.to.row + 2;
                    const footerRow = worksheet.getRow(footerRowIndex);
                    worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

                    footerRow.getCell(1).value = '-';
                    footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
                    footerRow.getCell(1).alignment = { horizontal: 'right' };
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'HistorialDeFacturas.xlsx');
                    });
                });
                e.cancel = true;
            },

            closeAlert() {
                this.alert.show = false;

                if (this.alert.options == "reloadInvoices") {
                    this.getAllInvoices()
                }
            },
            acceptAlert() {
                this.alert.show = false;

                if (this.alert.options == "reloadInvoices") {
                    this.getAllInvoices()
                }
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },

            getAllInvoices() {
                this.loading = true
                this.$API.batchs.getInvoicesPOSHistory(this.filter)
                    .then(response => {
                        this.loading = false
                        this.batchs = response
                        console.log('this.batchs ', this.batchs)
                        this.formatInvoiceFields();
                        console.log(this.batchs);
                    })
            },
            async getBatches() {
                try {
                    this.loading = true;
                    const { data: response } = await this.$API.accountingperiodbatch.getAccountingPeriodBatch();

                    console.log( 'response aca', response );
                    this.batchs = response;
                } catch (error) {
                    console.log( error );
                    this.showAlert(
                        "danger",
                        "Error al obtener los datos",
                        "Hubo un problema al intentar obtener los movimientos del periodo solicitado. " + error.message
                    );
                } finally {
                    this.loading = false;
                }
            },
            async disableBatch(value) {
                this.loading = true;
                await this.$API.accountingperiodbatch.disableAccountingBatch({ id_accounting_period_batch: value })
                    .then(res => {
                        if(res.success) {
                            this.loading = false;
                            this.showAlert("success", "Lotes de partidas", res.message);
                            this.getBatches();
                        }
                        else{
                            throw new Error(res.message || null);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.loading = false;
                        this.showAlert("danger", "Lotes de partidas", error.message || "Ocurrio un error al deshabilitar el lote de partidas, intentelo más tarde.");
                    });
            },
            consultBatchProcess() {
                this.loading = true

                this.showAlert(
                    "warning",
                    "Advertencia",
                    `El Ministerio de Hacienda no ha dado una respuesta sobre el lote de contingencia enviado, favor intentar más tarde.
                    Si el error persiste, favor comunicarse a nuestro contact center.`,
                    "reloadInvoices"
                ); 

                this.$API.invoices.consultBatchProcess(this.filter).then(response => {
                    if(response?.status == 1){
                        this.loading = false

                        this.showAlert(
                        "success",
                        "Lotes consultados y facturas actualizadas",
                        "Se ha consultado lotes enviados por contingencia y se ha recibido respuesta, las facturas han sido actualizadas con su respectivo sello y estado de PROCESADAS o RECHAZADAS.",
                        "reloadInvoices"
                        ); 
                    }
                    else if(response?.status == -1){
                        this.loading = false

                        this.showAlert(
                        "warning",
                        "Advertencia",
                        `El Ministerio de Hacienda no ha dado una respuesta sobre el lote de contingencia enviado, favor intentar más tarde.
                        Si el error persiste, favor comunicarse a nuestro contact center.`,
                        "reloadInvoices"
                        ); 
                    }
                    else{
                        this.loading = false
                        
                        this.showAlert(
                        "success",
                        "Lotes consultados",
                        "No se encontro lotes enviados por contingencia recientes.",
                        "reloadInvoices"
                        );
                    }
                })
            },

            consultBatchProcess2() {
                this.loading = true

                this.$API.invoices.consultBatchProcess2(this.filter).then(response => {
                    if(response?.status == 1){
                        this.loading = false

                        this.showAlert(
                        "success",
                        "Lotes consultados y facturas actualizadas",
                        "Se ha consultado lotes enviados por contingencia y se ha recibido respuesta, las facturas han sido actualizadas con su respectivo sello y estado de PROCESADAS.",
                        "reloadInvoices"
                        ); 
                    }
                    else if(response?.status == -1){
                        this.loading = false

                        this.showAlert(
                        "warning",
                        "Advertencia",
                        `El Ministerio de Hacienda no ha dado una respuesta sobre el lote de contingencia enviado, favor intentar más tarde.
                        Si el error persiste, favor comunicarse a nuestro contact center.`,
                        "reloadInvoices"
                        ); 
                    }
                    else{
                        this.loading = false
                        
                        this.showAlert(
                        "success",
                        "Lotes consultados",
                        "No se encontro lotes enviados por contingencia recientes.",
                        "reloadInvoices"
                        );
                    }
                })
            },

            contingencyProcess() {
                this.loading = true
                this.$API.invoices.contingencyProcess(this.filter).then(response => {
                    if(response?.resultContingency?.estado == "RECIBIDO"){
                        let mensaje = "Se ha enviado exitosamente las facturas en estado de contingencia. <br/><br/>";
                        mensaje += "Estado contingencía: " + response.resultContingency.estado + "<br/>";
                        mensaje += "Estado recepción lote: " + response.resultRecepcionLote.estado + "<br/>";
                        mensaje += "Mensaje contingencía: <br/> - "+ response.resultContingency.mensaje + "<br/>";
                        mensaje += "Mensaje recepción lote: <br/> - "+ response.resultRecepcionLote.descripcionMsg + "<br/>"; 
                        mensaje += "Observaciones: <br/>"
                        if(response.resultContingency.observaciones.length == 0){
                            mensaje += "- Ninguna observación <br/>"
                        }
                        else{
                            for(let i = 0; i < response.resultContingency.observaciones.length; i++){
                                mensaje += "- "+ response.resultContingency.observaciones[i] + "<br/>"
                            }
                        }

                        this.loading = false
                            
                        this.showAlert(
                        "successHtml",
                        "Proceso de contingencia realizado",
                        mensaje
                        );
                    }
                    else{
                        let mensaje = "Se ha rechazado la contingencia, tomar en cuenta las observaciones del Ministerio de Hacienda y realizarla de nuevo.<br/><br/>";
                        mensaje += "Estado: " + response.estado + "<br/>";
                        mensaje += "Mensaje del DTE: <br/> - "+ response.mensaje + "<br/>";
                        mensaje += "Observaciones DTE: <br/>"
                        if(response.observaciones.length == 0){
                        mensaje += "- Ninguna observación <br/>"
                        }
                        else{
                            for(let i = 0; i < response.observaciones.length; i++){
                                mensaje += "- "+ response.observaciones[i] + "<br/>"
                            }
                        }

                        this.loading = false
                            
                        this.showAlert(
                        "successHtml",
                        "Proceso de contingencia rechazado",
                        mensaje
                        );
                    }
                })
                /*.catch((err) => {
                    alert("aqui")
                    let message = "Ocurrio un error crítico al realizar el proceso de contingencia. ¡Por favor contáctarse con el administrador del sistema!"
                    message += "<br/><br/>"+JSON.stringify(err)
                    this.showAlert(
                        "warningHtml",
                        "Advertencia",
                        message
                    )
                })*/
            },

            addNDorNC(data){
                data.data.IsNDorNC = true
                this.batchDetails = data 
                this.showModal = true
            },

            showBatchDetail(data){
                //console.log( 'data aca', data.data );
                data.data.IsNDorNC = false;
                this.batchDetails = data.data;
                this.showModal = true;
            },
            showAccountingEntryDetail(data){
                //console.log( 'data aca', data );
                this.showModal = false;
                this.$store.dispatch('addWindow', {
                    name: 'Acounting',
                    component: 'Acounting',
                    unique: false,
                    meta: { accountingEntryToModify: {...data} }
                });
            },
        }
    }
</script>

<style scoped>
.hoveredDiv{
    cursor: pointer;
}

.hoveredDiv:hover{
    background-color: #4e9ed3 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.hoveredDiv2{
    cursor: pointer;
}

.hoveredDiv2:hover{
    background-color: rgb(41, 182, 147) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.btn-add{
  background-color: #00A178 !important;
  color: white;
}
.btnsave {
  background-color: #3498db !important;
  color: white;
}
.background {
    background: white !important;
    color: white;
}
.btn-wrap-text {
padding: 10px 16px; 
width: fit-content;
font-weight: 500;
font-size:0.875rem;
align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
   
}


@media screen and (max-width: 900px) {
    .item {
        padding-bottom: 20px; /* Añade padding en la parte inferior en pantallas más pequeñas */
    }
}



@media screen and (max-width: 521px) {
    .btn-padding {
        margin-bottom: 20px; 
    }
    .btn-wrap-text {
  
  text-align: center; 
  line-height: 1.5; 
  width: 100%; 
 
}

}


</style>