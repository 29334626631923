<template>
    <div>
        <Block v-if="loading" />
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end">
                <v-btn
                    class="btnsave mr-1"
                    small
                    @click="getAutomaticEntries"
                >
                    Ver Movimientos
                   
                </v-btn>
                <v-btn
                    class="btn-add mr-1"
                    small
                    @click="processDetailsBeforeInit"
                >
                    Contabilizar
                   
                </v-btn>
                
            </div>
        </v-card>
        <v-row class="mt-5 ml-2 d-flex justify-start">
            <v-col cols="12" md="6">
                <DatePicker
                    label="Desde"
                    v-model="dateFrom"
                    :value="dateFrom"
                    @dateSelected="(param) => (dateFrom = param)"
                />
            </v-col>
            <v-col cols="12" md="6">
                <DatePicker
                    dense
                    label="Hasta"
                    v-model="dateTo"
                    :value="dateTo"
                    @dateSelected="(param) => (dateTo = param)"
                />
            </v-col>
        </v-row>
        <!-- <v-row class="ml-2"> -->
        <v-radio-group row v-model="type" class="ml-9 d-flex justify-center">
            <v-col md="2">
            </v-col>
            <v-col cols="12" md="4">
                <v-radio
                    label="Realizar una partida por operación"
                    :value="2"
                ></v-radio>
            </v-col>
            <v-col cols="12" md="4">
                <v-radio
                    label="Realizar una partida por movimiento"
                    :value="1"
                ></v-radio>
            </v-col>
            <v-col md="2">
            </v-col>
        </v-radio-group>
            <!-- <v-col cols="12" md="12" class="">
                <v-checkbox
                    label="Consolidar movimientos en una sola partida por operación"
                    :value="false"
                ></v-checkbox>
            </v-col> -->
        <!-- </v-row> -->
        <v-row class="mt-2 ml-2 d-flex justify-start">
            <v-col cols="12" md="3">
                <v-checkbox
                    v-model="form.sales.value"
                    single-line
                >
                    <template v-slot:label>
                        <span class="text-h5">Ventas</span>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-radio-group v-model="form.sales.sales2" row class="ml-9" :disabled="!form.sales.value">
            <v-col cols="12" md="6">
                <v-radio
                label="Consolidar ventas por estado de la factura (Contado/Crédito)"
                :value="true"
                ></v-radio>
            </v-col>
            <v-col cols="12" md="6">
                <v-radio
                    label="Consolidar ventas por método de pago"
                    :value="false"
                ></v-radio>
            </v-col>
        </v-radio-group>
        <v-radio-group v-model="form.sales.sales3" row class="ml-9" :disabled="!form.sales.value">
            <v-col cols="12" md="6">
                <v-radio
                    label="Contabilizar Costo de ventas"
                    :value="true"
                ></v-radio>
            </v-col>
            <v-col cols="12" md="6">
                <v-radio
                    label="No Contabilizar Costo de Ventas"
                    :value="false"
                ></v-radio>
            </v-col>
        </v-radio-group>
        <v-row v-if="false" class="mt-2 ml-2 d-flex justify-start">
            <v-col cols="12" md="3">
                <v-checkbox
                    v-model="form.purchases.value"
                    single-line
                >
                    <template v-slot:label>
                        <span class="text-h5">Compras</span>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <!-- <v-radio-group v-if="false" v-model="form.purchases.purchases2" row class="ml-9" :disabled="!form.purchases.value">
            <v-col cols="12" md="6">
                <v-radio
                label="Consolidar ventas por estado de la factura (Contado/Crédito)"
                :value="true"
                ></v-radio>
            </v-col>
            <v-col cols="12" md="6">
                <v-radio
                    label="Consolidar ventas por método de pago"
                    :value="false"
                ></v-radio>
            </v-col>
        </v-radio-group> -->
        <!-- <v-radio-group v-model="form.sales.sales3" row class="ml-9" :disabled="!form.sales.value">
            <v-col cols="12" md="6">
                <v-radio
                    label="Contabilizar Costo de ventas"
                    :value="true"
                ></v-radio>
            </v-col>
            <v-col cols="12" md="6">
                <v-radio
                    label="No Contabilizar Costo de Ventas"
                    :value="false"
                ></v-radio>
            </v-col>
        </v-radio-group> -->
        <DxDataGrid v-if="submittedType === 1" :dataSource="DataTable" :showBorders="true" :scrolling="{ useNative: true }" :column-auto-width="true">
            <DxPager showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
            <DxPaging defaultPageSize="5" />
            <DxColumn :group-index="0" data-field="Op" caption="Operación"></DxColumn>
            <DxColumn :group-index="1" data-field="ID_Invoice" caption="ID del Documento"></DxColumn>
            <DxColumn data-field="account_code" caption="Cuenta Contable" :width="300"></DxColumn>
            <DxColumn data-field="account_description" caption="Concepto"></DxColumn>
            <!-- <DxColumn data-field="Deber" caption="Deber"></DxColumn>
            <DxColumn data-field="Haber" caption="Haber"></DxColumn> -->
            <DxColumn cell-template="debit-template" caption="Deber"></DxColumn>
            <DxColumn cell-template="credit-template" caption="Haber"></DxColumn>
            <template #debit-template="{ data }">
                {{ formatedMoneyFieldFromChild( 'Deber', data.data ) }}
            </template>
            <template #credit-template="{ data }">
                {{ formatedMoneyFieldFromChild( 'Haber', data.data ) }}
            </template>
        </DxDataGrid>
        <DxDataGrid v-if="submittedType === 2" :dataSource="DataTable" :showBorders="true" :scrolling="{ useNative: true }" :column-auto-width="true">
            <DxPager showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
            <DxPaging defaultPageSize="5" />
            <DxColumn :group-index="0" data-field="Op" caption="Operación"></DxColumn>
            <!-- <DxColumn :group-index="1" data-field="ID" caption="ID del Documento"></DxColumn> -->
            <DxColumn data-field="account_code" caption="Cuenta Contable"></DxColumn>
            <DxColumn data-field="account_description" caption="Concepto"></DxColumn>
            <!-- <DxColumn data-field="Deber" caption="Deber"></DxColumn>
            <DxColumn data-field="Haber" caption="Haber"></DxColumn> -->
            <DxColumn cell-template="debit-template" caption="Deber"></DxColumn>
            <DxColumn cell-template="credit-template" caption="Haber"></DxColumn>
            <template #debit-template="{ data }">
                {{ formatedMoneyFieldFromChild( 'Deber', data.data ) }}
            </template>
            <template #credit-template="{ data }">
                {{ formatedMoneyFieldFromChild( 'Haber', data.data ) }}
            </template>
        </DxDataGrid>
        <!-- <v-row class="mt-5">
        </v-row> -->

        <v-row class="ml-2">
            <v-col cols="12" md="4">
                <v-card-title> Total de Débitos: {{ formatedMoneyField( 'TotalDebits' ) }} </v-card-title>
            </v-col>
            <v-col cols="12" md="4">
                <v-card-title> Total de Créditos: {{ formatedMoneyField( 'TotalCredits' ) }} </v-card-title>
            </v-col>
            <v-col cols="12" md="4">
                <v-card-title v-if="diff == 0 || diff == '0.00'">
                    Diferencia: {{ formatedMoneyField( 'diff' ) }}
                </v-card-title>
                <v-card-title v-else style="color: red">
                    Diferencia: {{ formatedMoneyField( 'diff', this ) }}
                </v-card-title>
            </v-col>
        </v-row>
        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        />

    </div>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import DatePicker from "@/components/DatePicker";
    import {
        DxDataGrid,
        DxPager,
        DxPaging,
        DxColumn
    } from 'devextreme-vue/data-grid';
    import { convertDateFormat2 } from "@/helpers/formatDate"
    import Block from '@/components/Block.vue';
    import Alerts from '@/components/Alerts.vue';
import { numberWithCommas } from "../../../helpers/money";
import { onlyUnique } from "../../../helpers/object";
    export default {
        name: "AcountAutomaticDetail",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            DxColumn,
            Block,
            Alerts,
            DatePicker
        },
        props: ['win'],
        data() {
            return {
                Currency: JSON.parse(localStorage.getItem("branch")).Currency,
                type: 2,
                submittedType: 2,
                DataTable: [],
                panel: 0,
                tab: null,
                key: 0,
                loading: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                form: {
                    sales: {
                        value: false,
                        sales2: true,
                        sales3: true,
                    },
                    purchases: {
                        value: false,
                        purchases2: true,
                        purchases3: true
                    },
                    cost: false,
                },
                dateTo: null,
                dateFrom: null,
                formatConfig: {
                    char: '/',
                    dayIndex: 0,
                    monthIndex: 1,
                    yearIndex: 2
                },
                targetFormat: "yyyy-mm-dd",
                accounting_entry_types: {
                    Venta: 4,
                    Compra: 1,
                    Costo: 1
                }
            }
        },
        computed:{
            TotalDebits() {
                let returnValue = 0;

                for( const element of this.DataTable ) {
                    returnValue += element.Deber;
                }

                return returnValue;
            },
            TotalCredits() {
                let returnValue = 0;

                for( const element of this.DataTable ) {
                    returnValue += element.Haber;
                }

                return returnValue;
            },
            diff() {
                return ( parseFloat( this.TotalDebits ) - parseFloat( this.TotalCredits ) ).toFixed( 2 );
            }
        },
        mounted() {

            this.dateTo = this.moment().format("DD/MM/YYYY");
            this.dateFrom = this.moment().format("DD/MM/YYYY");
        },
        methods: {
            formatedMoneyField( field ) {
                return `${ this.Currency }${ numberWithCommas( this[ field ] ) }`;
            },
            formatedMoneyFieldFromChild( field, obj ) {
                return `${ this.Currency }${ numberWithCommas( obj[ field ] ) }`;
            },
            async getAutomaticEntries(){
                this.loading = true;
                try {
                    this.submittedType = this.type;
                    const requestObj = {
                        type: this.type,
                        dateFrom: convertDateFormat2( this.dateFrom, this.formatConfig, this.targetFormat ),
                        dateTo: convertDateFormat2( this.dateTo, this.formatConfig, this.targetFormat ),
                        ...this.form
                    };

                    // console.log( requestObj );
                    const { data } = await this.$API.branchaccountcatalog.automaticAccountingEntries( { ...requestObj } );
                    // console.log( 'automaticAccountingEntries ', data )
                    this.DataTable = [ ...data ];
                } catch (error) {
                    console.log(error);
                    this.showAlert(
                        "error",
                        "Error al obtener los datos",
                        "Hubo un problema al intentar obtener los movimientos del periodo solicitado. " + error.message
                    );
                } finally {
                    this.loading = false;
                }
            },
            processDetailsBeforeInit() {
                this.showAlert(
                    'questionWithInnerHTML',
                    'Advertencia, Proceso Delicado!',
                    `<p style="color: whitesmoke !important;">
                        El proceso que está a punto de ejecutar debe ser ejecutado con competencia y conciencia de lo que se está haciendo. Si se ejecuta sin tener las consideraciones necesarias podría provocar los siguientes inconvenientes:
                        <ol>
                            <li>Lentitud en el sistema dentro de horas laborales</li>
                            <li>Duplicidad de registros contables</li>
                            <li>Incongruencias y descuadres contables</li>
                        </ol>
                    </p>`,
                    'processDetails'
                );
            },
            async processDetails() {
                this.loading = true;
                try {
                    if( !this.validateData() ) {
                        return;
                    }
    
                    let accountingEntriesToInsert;
                    const fromDate = new Date( convertDateFormat2( this.dateFrom, this.formatConfig, this.targetFormat ) );
                    // fromDate.setDate( fromDate.getDate() + 1 );
                    const Period_From = fromDate.toISOString().split('T')[0];
                    const toDate = new Date( convertDateFormat2( this.dateTo, this.formatConfig, this.targetFormat ) );
                    // toDate.setDate( toDate.getDate() + 1 );
                    const Period_To = toDate.toISOString().split('T')[0];
                    let batchSpecification = `Lote de Partidas Automáticas `;
                    batchSpecification += `${ this.type === 1 ? 'Por Movimiento' : 'Por Operación' }, `;
                    batchSpecification += `TIPOS: `;
                    let sales = false;
                    let cost = false;
                    let purchases = false

                    if( this.form.sales ) {
                        sales = true;
                        batchSpecification += `VENTA por ${ this.form.sales.sales2 ? 'estado de la factura' : 'método de pago' }`
                    }

                    if( this.form.sales.sales3 ) {
                        cost = true;
                        batchSpecification += ` | COSTO`;
                    }

                    if( this.form.purchases.value ) {
                        purchases = true
                        batchSpecification += ` | COMPRA`;
                    }

                    if( this.type === 2 ) {
                        accountingEntriesToInsert = this.accountingEntriesByOp();
                    }
                    else if( this.type === 1 ) {
                        accountingEntriesToInsert = this.accountingEntriesByDocument();
                    }
                    await this.$API.accountingentryheader.bulkCreateAccountingEntryHeader( {
                        data: {
                            accountingEntriesToInsert,
                            type: this.type,
                            Period_From,
                            Period_To,
                            batchSpecification,
                            sales,
                            cost,
                            purchases
                        },
                        params: {
                            dateFilter_dateStart: Period_From,
                            dateFilter_dateEnd: Period_To,
                            dateFilter_type: 1,
                            sales,
                            cost,
                            purchases
                        }
                    } );

                    this.showAlert(
                        "success",
                        `Periodo de ${ this.dateFrom } a ${ this.dateTo } Procesado Correctamente`,
                        `Se han insertado las partidas contables del período DE ${ this.dateFrom } A ${ this.dateTo }`
                    );
                } catch (error) {
                    this.showAlert(
                        "danger",
                        "Error al Contabilizar",
                        "Hubo un problema al intentar obtener los movimientos del periodo solicitado. " + error.message
                    );
                } finally {
                    this.loading = false;
                }

            },
            accountingEntriesByOp() {
                const ops = this.DataTable.map( current => {
                    const { Op } = current
                    return Op;
                } );
                const uniqueOps = ops.filter( onlyUnique );
                const opsObj = {};
                // const now = new Date().toISOString().split('T')[0];

                for( const op of uniqueOps ) {
                    const filteredDetails = this.DataTable.filter( current => {
                        const { Op } = current;
                        return Op === op;
                    } );

                    const toDate = new Date( convertDateFormat2( this.dateTo, this.formatConfig, this.targetFormat ) );
                    toDate.setDate( toDate.getDate() + 1 );

                    opsObj[ op ] = {};
                    opsObj[ op ].header = {
                        id_accounting_entry_type: this.accounting_entry_types[ op ],
                        accounting_entry_date: toDate.toISOString().split('T')[0],
                        accounting_entry_comment: `Partida automática de ${ op }, PERIODO: de ${ convertDateFormat2( this.dateFrom, this.formatConfig, this.targetFormat ) } a ${ convertDateFormat2( this.dateTo, this.formatConfig, this.targetFormat ) }`,
                        accounting_entry_value: parseFloat(
                            filteredDetails.reduce(
                                ( acc, current ) => {
                                    const { Amount, id_entry_detail_type } = current;
                                    return id_entry_detail_type === 1 ? acc + parseFloat( Amount ) : acc ;
                                },
                                0
                            ).toFixed( 2 )
                        )
                    };
                    
                    opsObj[ op ].details = filteredDetails.map( current => {
                        const {
                            id_account,
                            id_entry_detail_type,
                            Amount: amount,
                            account_description: concept
                        } = current;

                        return {
                            id_account,
                            id_entry_detail_type,
                            amount: amount.toFixed( 2 ),
                            concept
                        };
                    } );
                }

                return opsObj;
            },
            accountingEntriesByDocument() {
                const ops = this.DataTable.map( current => {
                    const { Op } = current
                    return Op;
                } );
                const documents = this.DataTable.map( current => {
                    const { ID_Invoice } = current
                    return ID_Invoice;
                } );
                const uniqueOps = ops.filter( onlyUnique );
                const uniqueDocuments = documents.filter( onlyUnique );
                const documentsObj = {};

                for( const op of uniqueOps ) {
                    const filteredOps = this.DataTable.filter( current => {
                        const { Op } = current;
                        return Op === op;
                    } );

                    documentsObj[ op ] = {};
                    for( const invoice of uniqueDocuments ) {
                        const filteredDetails = filteredOps.filter( current => {
                            const { ID_Invoice } = current;
                            return ID_Invoice === invoice;
                        } );

                        if( filteredDetails.length > 0 ) {
                            const toDate = new Date( convertDateFormat2( this.dateTo, this.formatConfig, this.targetFormat ) );
                            toDate.setDate( toDate.getDate() + 1 );

                            documentsObj[ op ][ invoice ] = {};
                            documentsObj[ op ][ invoice ].header = {
                                id_accounting_entry_type: this.accounting_entry_types[ op ],
                                accounting_entry_date: toDate.toISOString().split('T')[0],
                                accounting_entry_comment: `Partida automática de ${ op } del documento ${ invoice }, PERIODO: de ${ convertDateFormat2( this.dateFrom, this.formatConfig, this.targetFormat ) } a ${ convertDateFormat2( this.dateTo, this.formatConfig, this.targetFormat ) }`,
                                accounting_entry_value: parseFloat(
                                    filteredDetails.reduce(
                                        ( acc, current ) => {
                                            const { Amount, id_entry_detail_type } = current;
                                            // console.log( 'id_entry_detail_type ', id_entry_detail_type, current )
                                            return id_entry_detail_type === 1 ? acc + parseFloat( Amount ) : acc ;
                                        },
                                        0
                                    ).toFixed( 2 )
                                )
                            };
                            
                            documentsObj[ op ][ invoice ].details = filteredDetails.map( current => {
                                const {
                                    id_account,
                                    id_entry_detail_type,
                                    Amount: amount,
                                    account_description: concept
                                } = current;
        
                                return {
                                    id_account,
                                    id_entry_detail_type,
                                    amount: amount.toFixed( 2 ),
                                    concept
                                };
                            } );
                        }
                    }
                }
                return documentsObj;
            },
            validateData() {
                if( this.DataTable?.length > 0 ) {
                    return true;
                } else {
                    return false;
                }
            },
            closeAlert() {
                this.alert.show = false; 
            },
            acceptAlert() {
                this.alert.show = false;
                if( this.alert.options ) {
                    this[ this.alert.options ]();
                }
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
           
        },

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>