<template>
    <v-expansion-panel>
        <template>
            <v-expansion-panel-header class="mt-2">
                  Partida automaticas
  
                <template v-slot:actions>
                    <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                        <v-icon color="primary">mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})" icon small>
                        <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        Partidas automaticas
                        <v-icon>mdi-bank-transfer</v-icon>
                    </v-tab>
                    <v-tab href="#tab-2">
                        Lotes de partidas
                        <v-icon>mdi-transfer</v-icon>
                    </v-tab>
                </v-tabs>
  
                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <AcountAutomaticDetail :win="win" :key="key" />               
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <AccountsBatch :win="win" :key="key" />               
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-expansion-panel-content>
        </template>
        
    </v-expansion-panel>
  </template>
  
  <script>
    
    //import {mapState} from 'vuex';
    //import AccountingItem from '../partial/AccountingItem.vue';
    import AcountAutomaticDetail from '../partial/AcountAutomaticDetail.vue';
    import AccountsBatch from '../partial/AccountsBatch.vue';

    export default {
        name: "AcountingAutomatic",
        components: {
            AcountAutomaticDetail,
            AccountsBatch,
        },
        props: ['win'],
        data() {
            return {
                panel: 0,
                tab: null,
                key: 0,
                
                
            }
        },
        computed:{
            /*...mapState('customerData', {
                tab: 'tab-1'
            }),*/
        },
        methods: {
            reload() {
                this.key++;
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
            },
        },
  
    }
  </script>
  
  <style scoped>
  
  </style>